.create_post_channel_modal {
  &-wrapper {
    padding: 20px 0 30px 0;
    &-button {
      height: 44px;
      width: 100%;
      border: none;
      background: transparent;
      margin-bottom: 20px;
      &-card {
        display: flex;
        &-icon {
          width: 44px;
          background-color: #f2f2f5;
          border-radius: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          svg {
            font-size: 22px;
          }
        }
        &-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          &-title {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
          }
          &-subtitle {
            font-weight: 400;
            font-size: 12px;
            color: #0007139f;
            margin: 0;
          }
        }
      }
    }
  }
}

.ant-drawer-content-wrapper {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        padding: 12px;

        .ant-drawer-header-title {
          justify-content: flex-end;
          .ant-drawer-close {
            width: 34px;
            height: 34px;
            margin: 0;
            background-color: #f2f2f5;
            border-radius: 17px;
            svg {
              fill: #000;
            }
          }
        }
      }
      .ant-drawer-body {
        padding: 0;
        .community-drawer {
          &_static-list {
            border-bottom: 1px solid #dddde3;
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 8px 0;
            &_button {
              width: 100%;
              background: none;
              border: none;
              text-align: left;
              height: 48px;
              padding: 0 0 0 10px;
              &_wrapper {
                font-weight: 400;
                font-size: 16px;
                display: flex;
                align-items: center;
                &_icon-wrapper {
                  height: 40px;
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 16px;
                }
                &_svg-wrapper {
                  height: 40px;
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &_emoji {
                    height: 32px;
                    width: 32px;
                    border-radius: 16px;
                    background-color: var(--gray_lighter);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  svg {
                    font-size: 26px;
                  }
                }
                &_img-wrapper {
                  height: 40px;
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  img {
                    border-radius: 20px;
                  }
                }
              }
              &_link-wrapper {
                padding: 8px;
                height: 40px;
                font-weight: 500;
                font-size: 14px;
                display: flex;
                &_logo {
                  font-size: 16px;
                  height: 24px;
                  width: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                }
              }
            }
            &_active-section {
              background-color: var(--primary_light);
              .community-drawer_static-list_button_wrapper {
                font-weight: 500;
                color: var(--primary-active-color);
                &_svg-wrapper {
                  &_emoji {
                    background-color: var(--primary-shadow-color);
                  }
                  svg {
                    fill: var(--primary);
                  }
                }
              }
            }
          }
          &_dynamic-list {
            .ant-collapse {
              .ant-collapse-item {
                background-color: #fff;
                .ant-collapse-header {
                  border-bottom: 1px solid #dddde3;
                  border-top: none;
                  padding-left: 20px;
                  height: 64px;
                  align-items: center;
                  .ant-collapse-expand-icon {
                    svg {
                      transform: rotate(0deg);
                      transition: transform 300ms;
                      font-size: 20px;
                    }
                  }
                }
                .ant-collapse-content {
                  border: none;
                  .ant-collapse-content-box {
                    padding: 0 0 8px 0;
                  }
                }
              }
              .ant-collapse-item-active {
                .ant-collapse-header {
                  border: none;
                  padding-bottom: 8px;
                  .ant-collapse-expand-icon {
                    svg {
                      transform: rotate(90deg);
                      transition: transform 300ms;
                    }
                  }
                }
              }
            }
          }
          &_empty-space {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}

@keyframes page-in {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
}

@keyframes page-out {
  0% {
    scale: 1;
  }
  100% {
    scale: 0;
  }
}

.channel-info-show {
  animation: page-in 300ms ease-in-out;
}
.channel-info-remove {
  animation: page-out 300ms ease-in-out;
}
.channel-info_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100vh;
  background: #f2f2f5;
  z-index: 10;

  &_scroll {
    height: calc(100vh - 60px);
    overflow: scroll;
    &_logo {
      display: flex;
      padding-top: 24px;
      justify-content: center;
      &_emoji {
        height: 96px;
        width: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        border-radius: 48px;
        background-color: #fff;
      }
      img {
        height: 96px;
        width: 96px;
        border-radius: 48px;
      }
    }
    &_channel-name {
      text-align: center;
      margin-top: 12px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
    }
    &_settings {
      height: 81px;
      margin: 0 12px 16px;
      border-radius: 6px;
      background-color: #fff;
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .ant-divider {
        margin: 0;
      }
      &_item {
        // height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_logo {
          align-items: center;
          display: flex;
          svg {
            height: 20px;
            width: 20px;
          }
          &_title {
            margin: 0;
            margin-left: 8px;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
    &_user-container {
      margin: 0 12px;
      border-radius: 6px;
      p {
        font-weight: 600;
        font-size: 16px;
      }
      &_users {
        background-color: #fff;
        border-radius: 6px;
        padding: 6px 12px 12px;
      }
    }
    .ant-divider {
      margin: 0;
    }
  }
}

.community-post_container {
  display: flex;
  align-items: center;
  padding: 12px 12px 3px;
  .siteBtn {
    margin-left: auto;
    align-self: flex-start;
  }
  .ant-avatar {
    flex-shrink: 0;
  }
  .ant-avatar,
  &_user-title {
    cursor: pointer;
    font-size: 16px;
    color: var(--text1) !important;
    font-weight: 600;
  }
  &_wrapper {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: calc(100% - 100px);
    &_channels {
      display: flex;
      align-items: center;
      * {
        font-weight: 500;
        font-size: 14px;
        color: var(--text3) !important;
      }
      > * + * {
        margin-left: 5px;
      }
    }
  }
}

.community-post-title_container {
  font-weight: 500;
  font-size: 14px;
  color: var(--text3) !important;
  display: inline-flex;
  flex: 0 1 auto;
  width: 100%;
}

.community-post-item_wrapper {
  background-color: var(--background);
  margin: 12px auto;
  width: 100%;
  position: relative;
  overflow: hidden;

  // &__repost-card {
  //   margin: 12px 12px 4px;
  //   // padding: 12px;
  //   border: 1px solid var(--border);
  //   border-radius: 6px;
  //   overflow: hidden;
  // }

  &.community-feed-post {
    margin: 0 auto;
    margin-block: 6px;
  }

  &_pinned {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--gray_light);

    span {
      font-size: 14px;
      color: #000 !important;
      font-weight: 500;
      margin-left: 6px;
    }
  }
}

.community-post-content-wapper {
  padding: 12px 12px 3px;
}

.community-post-content {
  color: var(--text2) !important;
  font-size: 14px;
  margin: 0 !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;

  a {
    color: var(--link) !important;
  }
}

.community-post-stats-wrapper {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 24px;

  > * {
    // padding: 12px;
    display: inline-flex;
    align-items: center;
  }

  .svgLike {
    fill: white;
    stroke: var(--text2);
    strokewidth: 2px;
    transition: all 0.15s ease;

    &.active {
      fill: var(--danger);
      stroke: var(--danger);
    }
  }

  .postWhatsAppWrapper {
    border-color: #1bd741;
  }

  &_share-wrapper {
    margin-left: auto;
    ._no {
      font-weight: 500;
    }
  }
}
.heart {
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  svg:first-child {
    position: absolute;
    transform: scale(1);
    transition: all 0.5s ease;
  }
  svg:last-child {
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: scale(0);
    transition: all 0.5s ease;
  }
  &.active {
    svg:first-child {
      transform: scale(0);
    }
    svg:last-child {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.stats-count {
  margin: 0;
  color: #000;
  margin-left: 5px;
}

.community-feed-poll_contailer {
  padding: 12px 12px 0px;
  &_wrapper {
    padding: 12px;
    border: 1px solid var(--border);
    border-radius: 6px;
  }
  h1 {
    color: #050505 !important;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #050505 !important;
    white-space: nowrap;
    padding: 0.64rem !important;
  }

  &_poll-option-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    > .siteBtn + .siteBtn {
      margin-top: 12px;
    }
    &_poll-option {
      border: 2px solid var(--primary) !important;
      color: var(--primary) !important;
      border-radius: 38px !important;
      justify-content: center;
      padding: 4px 0 !important;
      &:hover {
        background-color: var(--primary_light) !important;
      }
    }
    .active {
      background-color: var(--primary_light) !important;
    }
  }

  &_poll-answer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
    &_poll-answer {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      padding: 8px 12px;
      color: #050505;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &_option-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 1;
        font-weight: 400;
        font-size: 16px;
      }
      &_option-per {
        flex-shrink: 0;
        padding-left: 48px;
        z-index: 1;
        font-weight: 400;
        font-size: 16px;
        margin-right: 10px;
        svg {
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
      &_option-progress {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 0;
        border-radius: 20px;
        background-color: #f0f2f5;
        transition: width 0.3s ease;
        &.max {
          background-color: var(--primary_light);
        }
      }
    }
  }
}

.comment-input_container {
  padding: 6px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &_reply {
    display: flex;
    background-color: var(--gray_lighter);
    border-radius: 8px;
    padding-left: 10px;
    border-left: solid 4px var(--primary);
    justify-content: space-between;
    padding-right: 10px;
    &_comment {
      h4 {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
      }
    }
    &_corss-btn {
      height: 24px;
      width: 24px;
      svg {
        fill: #000;
        font-size: 24px;
      }
    }
  }
  &_ {
    display: flex;
    gap: 12px;
    &_input {
      border: none;
      .ant-input-wrapper {
        height: 40px;
        .ant-input {
          height: 40px;
          border: none;
          background-color: var(--gray_lighter);
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
        .ant-input-group-addon {
          border: none;
          background-color: var(--gray_lighter);
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          padding: 0;
          padding-right: 5px;
          .addon-button_wrapper {
            display: flex;
            width: 62px;
            &_button {
              height: 40px;
              width: 50%;
              background-color: transparent;
              border: none;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
            }
          }
        }
      }
    }
    &_send-button {
      height: 40px;
      width: 40px;
      button {
        height: 40px;
        width: 40px;
        border: none;
        background-color: var(--primary);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #fff;
          height: 18px;
          width: 18px;
        }
        &:disabled {
          background-color: var(--text5);
        }
      }
    }
  }
  &_media {
    height: 64px;
    position: relative;
    width: fit-content;
    min-width: 64px;
    background-color: var(--gray_lighter);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    &_uploaded-img {
      border-radius: 6px;
    }
    &_cross-btn {
      position: absolute;
      top: -6px;
      right: -6px;
      svg {
        fill: #000;
        font-size: 16px;
      }
    }
  }
}

.pdp-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  .community-post-item_wrapper {
    .scroll {
      height: calc(100vh - 125px);
      padding: 10px 0;
      overflow: scroll;
    }
  }
}

.disable-comment_wrapper {
  height: 60px;
  padding: 10px;
  background-color: var(--gray_lighter);
  display: flex;
  align-items: center;
  justify-content: center;
  &_text {
    margin: 0;
  }
}

.comment-card {
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 8px;
  &_avtar {
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-color: #000;
    border-radius: 20px;
    img {
      border-radius: 20px;
      object-fit: contain;
    }
  }
  &_body {
    width: 100%;
    &_name {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      .ant-space {
        gap: 8px;
        width: 20px;
        color: #000;
        .ant-space-item {
          width: 30px;
        }
      }
    }
    &_comment {
      p {
        word-break: break-word;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
      }
    }
    &_media {
      img {
        max-height: 110px;
        min-width: 110px;
        border-radius: 12px;
      }
    }
    &_action {
      margin-top: 8px;
      display: flex;
      align-items: center;
      &_like {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
        .heart {
          svg {
            width: 16px;
            height: 16px;
          }
        }
        p {
          margin: 0;
          margin-left: 4px;
          font-weight: 500;
          font-size: 12px;
          color: var(--text1);
        }
      }
      &_reply {
        button {
          color: var(--text4);
          span {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.commnet-view-more_button {
  color: var(--text4);
  margin-left: 55px;
  span {
    font-weight: 500;
    font-size: 12px;
  }
}

.comment-loader_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

// --------------------------------------------------Message Styles----------------------------------------------
.user-card {
  height: 56px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_user-wrapper {
    &_avatar {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
      h4 {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
      }
      &_emoji {
        height: 40px;
        width: 40px;
        max-width: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--gray_lighter);
      }
    }
  }
}

.new-message_search {
  margin: 6px 12px;
  border-radius: 8px;
}

.new-message_list {
  height: calc(100vh - 115px);
  padding: 0 12px;
  overflow: scroll;
}

.message-card {
  height: 58px;
  padding: 8px 12px;
  background-color: #fff;
  &_user-wrapper {
    display: flex;
    gap: 8px;
    &_avatar {
      max-width: 42px;
      height: 40px;
      width: 40px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 20px;
      img {
        height: 40px;
        width: 40px;
        max-width: 40px;
        border-radius: 20px;
      }
      &_emoji {
        height: 40px;
        width: 40px;
        max-width: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--gray_lighter);
      }
    }
    &_avatar-active {
      border: solid 1px var(--primary);
    }
    &_name {
      min-width: calc(100% - 114px);
      max-width: calc(100% - 114px);
      h4 {
        font-weight: 500;
        font-size: 16px;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
    }
    &_time {
      min-width: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      align-items: end;
      &_number {
        font-weight: 400;
        font-size: 12px;
        text-align: end;
        margin: 0;
      }
      &_unread {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: var(--primary);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &_count {
          font-weight: 500;
          font-size: 10px;
          margin: 0;
          color: #fff;
        }
      }
    }
  }
}
.new-message_container {
  width: 100%;
  height: calc(100vh - 180px);
  overflow: scroll;
}

.user-list {
  height: calc(100vh - 120px);
  overflow: scroll;
}

.list-title {
  p {
    font-weight: 400;
    font-size: 12px;
  }
}

.danger {
  color: var(--danger);
  height: 40px;
  display: flex;
  align-items: center;
  width: 200px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  button {
    display: flex;
    height: 100%;
    border: none;
    background: none;
    align-items: center;
    div {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffefef;
      margin-right: 10px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.delete-dropdown {
  ul {
    border-radius: 10px;
  }
}
