.create-post-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;

  .post-tag-item {
    padding: 4px 8px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    color: var(--text2);
    // color: var(--background);
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    background: var(--tertiary);
    // background-color: var(--primary);

    .post-tag-item-text {
      color: var(--text2);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    svg {
      flex-shrink: 0;
    }
  }
}

.createpost__container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: var(--app-height);
  .scroll__container {
    padding: 12px;
    height: 100%;
    overflow: auto;
  }

  .createpoll_options {
    h3 {
      font-weight: 500;
      font-size: 16px;
    }
    &_droppable-wrapper {
      &_draggable-wrapper {
        &_option-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 12px;
          &_input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            input {
              height: 40px;
              border-radius: 6px;
            }
            &_drag-icon {
              position: absolute;
              right: 0;
              height: 40px;
              width: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              svg {
                margin-right: 8px;
                font-size: 20px;
              }
            }
          }
          &_remove-wrapper {
            height: 40px;
            width: 40px;
            &_button {
              width: 40px;
              height: 100%;
              border: 1px solid #d9000351;
              background-color: transparent;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                fill: #c62a2f;
              }
            }
          }
        }
      }
    }
  }
  .createpost__description__container {
    margin: 24px 0;
    .createpost__description {
      width: 100%;
      border: none;
      outline: none;
      resize: none;
      font-size: 16px;
      font-weight: 400;
      color: var(--text1);
      background: none;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none !important;
    }
  }

  .createpost__repost-card {
    // padding: 12px;
    border: 1px solid var(--border);
    border-radius: 6px;
    overflow: hidden;
  }

  .createpost__media-card {
    position: relative;
    &_edit-button {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 36px;
      width: 100px;
      border-radius: 4px;
      background: #1c2024;
      border: none;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      z-index: 5;
      svg {
        align-self: center;
      }
    }
  }

  .createpost__attachment__image__edit__info {
    display: flex;
    align-items: center;
    background-color: var(--primary_light);
    padding: 8px;
    margin-top: 12px;
    border-radius: 6px;
    color: var(--primary);
    gap: 6px;
    font-size: 10px;
  }
}

.create-post-header {
  display: flex;
  gap: 12px;
  align-items: center;
  .create-post-header-details {
    width: calc(100vw - 91px);
    h5 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
    }
    .create-post-header-details-mango {
      display: flex;
      .create-post-header-details-mango-button {
        padding: 3px 6px;
        border-radius: 6px;
        color: var(--text2);
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--tertiary);
        gap: 6px;
        max-width: 100%;
        span {
          font-size: 10px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.loading__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.choose-layout_wrapper {
  height: 42px;
  margin: 0 12px;
  margin-bottom: 20px;
  display: flex;
  &_button {
    background-color: var(--gray_lighter);
    border: none;
    border-radius: 6px;
    height: 32px;
    width: 100%;
    svg {
      position: relative;
      top: 2px;
    }
  }
}

.createpost__attachment {
  position: relative;
  .createpost__attachment__actions {
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
    flex-direction: row;
    gap: 6px;
    z-index: 100;
    .createpost__attachment__actions__button {
      flex-shrink: 0;
      height: 40px;
      width: 40px;
      background: var(--tertiary);
      color: var(--text2);
      border-radius: 50%;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 6px;
      &:hover {
        background: var(--tertiary);
      }
    }
  }
}

.createpost__attachment__collage-image {
  border-radius: 0 !important;
  img {
    object-fit: cover !important;
  }
}

.createpost__attachment__image {
  aspect-ratio: 12 / 9;
  background-color: #000;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.createpost__attachment__video {
  width: 100%;
  aspect-ratio: 12/9;
  position: relative;
  .player-wrapper-drm-custom {
    min-height: calc(100vw * 9 / 16);
  }
}

.createpost__attachment__container {
  padding: 12px;
  border-top: 1px solid var(--border);
  &.borderless {
    border: none;
  }
  .createpost__attachment__title {
    font-size: 12px;
    font-weight: 500;
    color: var(--text3);
    margin-bottom: 12px;
  }
  .createpost__attachment__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .createpost__attachment__option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      .createpost__attachment__option__icon {
        height: 50px;
        width: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--tertiary);
        border-radius: 50%;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 0;
        color: var(--icon);
      }
      .createpost__attachment__option__label {
        font-size: 10px;
        font-weight: 400;
        color: var(--text4);
      }
    }
  }
}

.createpoll {
  .createpoll__question {
    h3 {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
    }
    margin: 24px 0;
  }
  .createpoll__option {
    .createpoll__option__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .createpoll__option__label {
        font-size: 13px;
        font-weight: 500;
        color: var(--text3);
      }
      button {
        padding: 0;
        color: var(--danger);
        font-weight: 600;
        height: max-content;
      }
    }
    .ant-input-affix-wrapper {
      padding: 8px 12px;
      border: 1px solid var(--text4);
      border-radius: 8px;
      input {
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        width: 100%;
        color: var(--text1);
      }
      .ant-input-show-count-suffix {
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
        color: var(--text2);
        margin: 0;
      }
    }
  }
  .createpoll_add-button {
    height: 40px;
    width: 150px;
    border-radius: 6px;
    svg {
      font-size: 18px;
      height: 18px;
      width: 18px;
      position: relative;
      top: 3px;
    }
    span {
      font-weight: 500;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.feed-filter-button-wrapper {
  .feed-filter-button {
    border: 1px solid var(--border);
    border-radius: 50px;
    font-size: 13px;
    font-weight: 500;
    margin-right: 6px;
    min-width: -webkit-max-content;
    padding: 3px 10px;
    &:focus {
      border: 1px solid var(--border);
    }
    .applied_filter {
      color: var(--primary);
      fill: var(--primary);
    }
    .button-text {
      font-size: 14;
      font-weight: 500;
      margin: 0 5px;
    }
    .dropdown-symbol {
      color: #000;
      position: relative;
      top: 2px;
    }
  }
  .applied_filter_button {
    border: 1px solid var(--primary);
    &:focus {
      border: 1px solid var(--primary);
    }
  }
}

.header-icon-wrapper {
  height: 20px;
  width: 20px;
  svg {
    height: 20px !important;
    width: 20px !important;
  }
}

.post-settings_container {
  margin-bottom: 20px;
  &_wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &_item {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--gray_lighter);
      border-radius: 6px;
      p {
        margin: 0;
      }
    }
  }
}

.post-schedule_container {
  padding: 12px;
  margin-bottom: 20px;
  &_info-wrapper {
    margin: 0;
    p {
      font-weight: 400;
      font-size: 14px;
      color: var(--text2);
    }
  }
  &_date-time-wrapper {
    h3 {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 6px;
      .ant-picker-input {
        display: flex;
        flex-direction: row-reverse;
        input {
          margin-left: 10px;
        }
      }
    }
  }
  &_date-time-wrapper + &_date-time-wrapper {
    margin-top: 8px;
  }
}

.date-time-popup {
  z-index: 9999;
}

.schedule-details_container {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 1px, 0px, 1px, 0px;
  border-style: solid;
  border-color: #0179e648;
  padding: 6px 12px;
  background: #0280ff12;
  &_info-wrapper {
    display: flex;
    svg {
      fill: #113264;
      align-self: center;
    }
    p {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      color: #113264;
      margin-left: 3px;
    }
  }
  &_actions-wrapper {
    display: flex;
    gap: 4px;
    &_button {
      height: 40px;
      width: 40px;
      border: none;
      border-radius: 20px;
      &_edit {
        background: #e1f0ff;
        svg {
          align-self: center;
          fill: #113264;
        }
      }
      &_remove {
        background: #ff010110;
        svg {
          align-self: center;
          fill: #c62a2f;
        }
      }
    }
  }
}

.add_redius {
  padding-top: 30px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.layout_container {
  display: flex;
  gap: 12px;
  height: 80px;
  padding: 0 16px;
  margin-top: 10px;
  margin-bottom: 30px;
  &_button {
    width: 50%;
    border-radius: 8px;
    border: none;
    &_wrapper {
      svg {
        height: 40px;
        width: 40px;
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
  &_active-button {
    border: 1px solid var(--primary);
    background-color: var(--primary_light);
    &_wrapper {
      svg {
        fill: var(--primary);
      }
      p {
        color: var(--primary);
      }
    }
  }
}

.poll-setting_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-weight: 500;
    font-size: 16px;
  }
  &_allow-result {
    height: 40px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    background-color: var(--gray_lighter);
    border-radius: 6px;
    h4 {
      font-weight: 500;
      font-size: 16px;
    }
  }
  &_allow-change-vote {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    background-color: var(--gray_lighter);
    border-radius: 6px;
    &_title-subtitle-wrapper {
      h4 {
        font-weight: 500;
        font-size: 16px;
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
  &_poll-duration {
    background-color: var(--gray_lighter);
    border-radius: 6px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &_select-wrapper {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-weight: 500;
        font-size: 16px;
        align-self: center;
        margin: 0;
      }
      button {
        height: 40px;
        width: 171px;
        border: 1px solid #01062f2c;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          color: #00041881;
        }
      }
    }
    &_date-time-wrapper {
      width: 100%;
      .ant-picker {
        width: 100%;
      }
    }
  }
}

.create_post_select-channel {
  &_container {
    margin: 12px;
    &_modal-wrapper {
      margin-top: 12px;
      &_group-wrapper {
        &_name {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          color: #0007139f;
          margin-bottom: 4px;
        }
        &_channel-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          border: 1px solid var(--border_input);
          background-color: #f9f9fb;
          margin-bottom: 8px;
          padding: 8px;
          border-radius: 4px;
          &_name-wrapper {
            display: flex;
            gap: 8px;
            &_svg-wrapper {
              background-color: var(--gray_light);
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
            }
            &_img-wrapper {
              background-color: var(--gray_light);
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              img {
                border-radius: 12px;
              }
            }
          }
        }
        &_select-channel-wrapper {
          background-color: var(--primary_light);
          border: 1px solid var(--primary);
          &_name-wrapper {
            &_svg-wrapper {
              background-color: var(--primary-shadow-color);
            }
            &_img-wrapper {
            }
          }
        }
      }
    }
  }
}

.two-media_container {
  display: flex;
  gap: 3px;
  .audio-dummy-img {
    width: 50%;
    .createpost__attachment {
      width: 100%;
    }
  }
  .createpost__attachment {
    width: 50%;
  }
}

.three-media_container {
  display: flex;
  justify-content: center;
  gap: 3px;
  &_first-media {
    width: 50%;
    align-self: center;
  }
  &_remaining-media {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 50%;
  }
}

.four-media_container {
  display: flex;
  justify-content: center;
  gap: 3px;
  &_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

.five-media_container {
  display: flex;
  gap: 3px;
  justify-content: center;
  &_two-media-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_three-media-wrapper {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    border-radius: 0;
  }
  &_remaining-count {
    position: relative;
    &_wrapper {
      width: 100%;
      border-radius: 0 !important;
      bottom: 0;
      filter: opacity(80%);
      font-size: 30px;
      color: #fff;
      font-weight: 600;
      position: absolute !important;
      z-index: 10;
    }
  }
}

.audio-dummy-img {
  position: relative;
  svg {
    fill: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: calc(50% - 21px);
    z-index: 9;
    font-size: 42px;
  }
}

.post-media-edit_container {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  &_item-wrapper {
    position: relative;
    width: 100%;
    &_remove-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      background-color: #fff;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      transform: rotate(45deg);
      svg {
        fill: #000;
        font-size: 38px;
        position: absolute;
        top: -3px;
        left: -3px;
      }
    }
    &_drag-icon {
      position: absolute;
      top: calc(50% - 10px);
      svg {
        fill: #fff;
        font-size: 20px;
      }
    }
  }
}
