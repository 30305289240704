.chat-room_container {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  &_wrapper {
    padding: 12px;
    height: inherit;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }
}

.chat-room-header {
  display: flex;
  height: 40px;
  gap: 8px;
  border: none;
  background: none;
  &_emoji-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--gray_lighter);
    font-size: 18px;
    min-width: 40px;
  }
  &_logo-wrapper {
    border-radius: 20px;
    img {
      border-radius: 20px;
      height: 40px;
      min-width: 40px;
    }
  }
  &_text-wrapper {
    display: flex;
    flex-direction: column;
    &_title {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
    &_subtitle {
      font-weight: 400;
      font-size: 10px;
      color: #0007139f;
      text-align: left;
      svg {
        font-size: 14px;
        position: relative;
        top: 3px;
        margin-right: 5px;
      }
    }
  }
}

.chat-room-header-wrapper {
  .default-header {
    display: flex;
    &__title {
      justify-content: flex-start !important;
    }
    &__button {
      min-width: 10% !important;
    }
  }
}

.chat-input_wrapper {
  min-height: 95px;
  padding: 8px;
  background-color: #fff;
  padding-bottom: 30px;
  max-height: 200px;
  height: fit-content;
  &_ {
    height: 52px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: flex-end;
    &_plus {
      width: 40px;
      height: 40px;
      button {
        height: 40px;
        width: 40px;
        border: none;
        background: none;
      }
    }
    &_input {
      width: 100%;
      display: flex;
      min-height: 37px;
      align-items: flex-end;
      background-color: var(--gray_lighter);
      padding-right: 8px;
      border-radius: 20px;
      height: fit-content;
      &_textarea {
        background-color: var(--gray_lighter);
        border: 0;
        border-radius: 20px;
        align-self: center;
        &:focus {
          outline: none;
          box-shadow: none !important;
          border: none;
        }
      }
      .addon {
        align-self: flex-end;
        button {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          background-color: var(--gray_lighter);
          border: none;
          background: none;
        }
      }
    }
    &_send {
      width: 40px;
      height: 40px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: none;
        background-color: var(--primary);
        padding: 0;
        svg {
          fill: #fff;
          font-size: 20px;
        }
      }
    }
  }
}

.chat-card {
  display: flex;
  padding: 6px;
  gap: 8px;
  height: auto;
  border-radius: 16px;
  background-color: #fff;
  &_avatar {
    height: 40px;
    width: 40px;
    min-width: 40px;
    img {
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &_text {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      &_name {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
      }
      &_time {
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        color: var(--text5);
        min-width: 60px;
      }
    }
    &_message {
      p {
        word-break: break-word;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
    &_action {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &_replay {
        display: flex;
        &_ {
          display: inline-flex;
          &_avtar {
            height: 24px;
            width: 24px;
            img {
              border-radius: 12px;
              object-fit: cover;
            }
          }
          &_avtar + &_avtar {
            margin-left: -5px;
          }
          &_count {
            background-color: #000;
            border-radius: 12px;
            font-weight: 500;
            font-size: 10px;
            text-align: center;
            color: #fff;
            padding-top: 4px;
          }
        }
        &_button {
          display: flex;
          align-items: center;
          button {
            border: none;
            background: none;
            color: var(--primary);
          }
          p {
            font-weight: 400;
            font-size: 12px;
            color: var(--text4);
            margin: 0;
          }
        }
      }
      &_reactions {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        &_emoji {
          height: 22px;
          min-width: 50px;
          width: fit-content;
          padding: 0 8px;
          border-radius: 11px;
          border: solid 1px var(--primary);
          background-color: var(--primary_light);
          text-align: center;
        }
      }
    }
  }
}

.chat-card + .chat-card {
  margin-top: 12px;
}

.touched {
  margin: 8px 12px;
  .emoji_container_wrapper {
    margin-bottom: 16px;
    ul {
      li {
        border-radius: 20px;
        background-color: var(--gray_lighter);
      }
    }
  }
  &_btn-wrapper {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 16px;
    &_button {
      height: 44px;
      border: none;
      background: none;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      gap: 12px;
      align-items: center;
      &_emoji {
        height: 44px;
        width: 44px;
        border-radius: 22px;
        background-color: var(--gray_lighter);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 24px;
        }
      }
    }
    .danger {
      color: var(--danger);
      .touched_btn-wrapper_button_emoji {
        background-color: #ffefef;
      }
    }
  }
}

.chat-room-pinned {
  min-width: 44px;
  display: flex;
  background: none;
  border: solid 1px var(--text5);
  height: 34px;
  width: 44px;
  padding: 0;
  text-align: end;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  justify-content: space-around;
  padding: 0 3px;
  background-color: var(--gray_lighter);
  svg {
    font-size: 13px;
    height: 16px;
    width: 16px;
    min-width: 16px;
  }
}

.pinned-chat-modal {
  &_container {
    height: 100vh;
    position: relative;
    &_chats {
      height: calc(-60px + 100vh);
      padding: 8px 12px 30px;
      overflow: auto;
      position: relative;
    }
  }
}

.reply-modal_container {
  height: 100vh;
  position: relative;
  &_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 60px);
    &_messages {
      margin: 12px;
      &_main {
      }
      &_replies {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: calc(100vh - 450px);
        overflow: scroll;
      }
    }
    &_input {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.chat-media-wrapper {
  background-color: #fff;
  .createpost__attachment__container {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.seleted-media_wrapper {
  display: flex;
  gap: 14px;
  padding: 12px 8px;
  width: 100%;
  overflow-x: scroll;
  &_media {
    height: 64px;
    max-height: 64px;
    width: 64px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray_lighter);
    border-radius: 8px;
    &_close {
      position: absolute;
      top: -10px;
      right: -10px;
      button {
        border: none;
        background: transparent;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 16px;
        &:hover {
          color: #000;
          border-color: #000;
        }
      }
    }
  }
}

.info-wrapper {
  padding: 12px;
  &_tabs {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-x: scroll;
    align-items: center;
    border-bottom: solid 1px var(--gray_lighter);
    margin-bottom: 10px;
    padding: 10px 0;
    white-space: nowrap;
    button {
      background-color: transparent;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      border: none;
      height: 40px;
      border-radius: 20px;
      padding: 0 12px;
      p {
        margin: 0;
      }
    }
    .selected {
      color: #fff;
      background-color: #000;
    }
  }
  &_users {
    max-height: calc(100vh - 260px);
    overflow: scroll;
  }
}

.full-page-model {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;
  .ant-modal-content {
    background: none;
    .ant-modal-body {
      padding: 0;
    }
  }
}
