@import '../../sass/mixins';

$header-height: 60px;

.header--activity__container {
  margin-top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  width: 100%;
  background-color: var(--background);
  border-bottom: 1px solid var(--border);
  flex-shrink: 0;
  &.borderless {
    border-bottom: none;
  }
  .header--activity {
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .header--activity__logo {
      width: 125px;
      img {
        width: 100%;
        display: block;
      }
      .header--activity__logo__text {
        font-size: 22px;
        font-weight: 600;
        color: var(--text1);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
    }
  }
}

.subscription__container {
  padding: 0 12px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .subscription__avatar__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .subscription--manage__button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--text2);
    font-weight: var(--font-weight-medium);
    padding: 8px 0px 8px 12px;
    display: flex;
    align-items: center;
    span {
      margin-left: 6px;
      @include font-size(12);
      color: var(--text2);
      font-weight: var(--font-weight-medium);
    }
  }
  .subscription__avatar__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
    .subscription__avatar {
      &.selected {
        border: 1.5px solid var(--primary);
        padding: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 32px;
        background-color: var(--background);
      }
      .subscription__avatar__image {
        background-color: var(--background);
        border: 1.5px solid var(--secondary);
        width: 34px;
        height: 34px;
        position: relative;
        border-radius: 50%;
        > * {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .subscription__avatar__text {
        margin: 0 6px;
        @include font-size(12);
        color: var(--text1);
        font-weight: var(--font-weight-medium);
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .subscription__extra-participant {
      height: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--tertiary);
      border-radius: 28px;
      margin-left: -12px;
      padding: 2px;
      padding: 0 12px;
      .subscription__extra-participant__text {
        @include font-size(10);
        color: var(--text1);
        font-weight: var(--font-weight-medium);
      }
    }
  }
}
.channel-switch-wrapper {
  height: 52px;
  padding: 8px;
  display: flex;
  gap: 8px;
  button {
    width: 50%;
    height: 40px;
    border: none;
    border-radius: 20px;
    span {
      font-weight: 400;
      font-size: 16px;
    }
  }
  &_active-btn {
    background-color: var(--primary_light) !important;
    span {
      font-weight: 500 !important;
      color: var(--primary-active-color);
    }
  }
}
.tags-filter__container {
  height: 45px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid var(--border);
  .tags-filter__text {
    padding-left: 12px;
    min-width: max-content;
    display: flex;
    align-items: center;
    height: 100%;
    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: var(--border);
      margin-left: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        position: absolute;
        bottom: 0px;
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: var(--text1);
      }
    }
  }
  .tags-filter__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    .tags-filter__tag {
      min-width: max-content;
      margin-right: 6px;
      padding: 3px 10px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 500;
      border: 1px solid var(--border);
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: var(--text2);
      }
      &.active {
        border-color: var(--primary);
        span {
          color: var(--primary);
        }
      }
    }
  }
}

.default-header__container {
  width: 100%;
  background-color: var(--background);
  border-bottom: 1px solid var(--border);
  flex-shrink: 0;
  &.borderless {
    border-bottom: none;
  }
  .default-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: $header-height;
    .default-header__button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: $header-height;
      height: $header-height;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background: none;
      outline: 0;
      border: none;
      padding: 0 12px;
      svg {
        width: 18px;
        height: 18px;
        display: block;
        color: var(--icon);
      }
    }
    .default-header__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $header-height;
      width: 100%;
      overflow: hidden;
      .default-header__title__text {
        @include font-size(16);
        color: var(--text1);
        font-weight: var(--font-weight-semibold);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
      }
    }
    .header--activity__actions {
      min-width: $header-height;
      padding: 0 12px;
    }
  }
}

.header--search-bar {
  border: 0px solid var(--border);
  border-radius: 0px;
  background-color: var(--background);
  padding: 8px 12px;
  .search-bar {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: var(--gray_light);
    padding: 0 12px;
    border-radius: 8px;

    .search-input {
      background-color: transparent;
      border: 0px solid var(--border);
      outline: none;
      width: 100%;
      height: 40px;
      padding: 0 12px;
      color: var(--text1);
      font-weight: 400;
      font-size: 14px;

      &::placeholder {
        color: var(--text2);
        font-weight: 400;
      }
    }

    .search-icon,
    .search-clear-icon {
      flex-shrink: 0;
    }
  }
}

.header--activity__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: $header-height;
  .header--activity__action {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: var(--tertiary);
    svg {
      width: 16px;
      height: 16px;
      display: block;
      color: var(--icon);
    }
  }

  .ant-badge-count {
    top: 6px;
    right: 6px;
    background-color: var(--danger);
  }
}

.message-header {
  width: 100%;
  background-color: var(--background);
  // border-bottom: 1px solid var(--border);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  .message-header__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 30px;
    height: $header-height;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background: none;
    outline: 0;
    border: none;
    padding: 0 12px;
    svg {
      width: 18px;
      height: 18px;
      display: block;
      color: var(--icon);
    }
  }
  .message-header__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: $header-height;
    width: 100%;
    overflow: hidden;
    .message-header__title {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: $header-height;
      width: calc(100vw - 100px);
      overflow: hidden;
      margin-left: 8px;
      .message-header__title__text {
        @include font-size(14);
        color: var(--text1);
        font-weight: var(--font-weight-semibold);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        margin-bottom: 2px;
      }
      .message-header__subtitle__text {
        @include font-size(10);
        color: var(--text3);
        font-weight: var(--font-weight-regular);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.community-header-before-title-action-button {
  border: none;
  height: 40px;
  width: 40px;
  background: transparent;
  svg {
    margin-top: 8px;
    height: 24px;
  }
}

.header-community {
  display: flex;
  height: 40px;
  gap: 8px;
  border: none;
  background: none;
  &_emoji-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--gray_lighter);
    font-size: 18px;
  }
  &_logo-wrapper {
    border-radius: 20px;
    img {
      border-radius: 20px;
      height: 40px;
    }
  }
  &_text-wrapper {
    display: flex;
    flex-direction: column;
    &_title {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
    &_subtitle {
      font-weight: 400;
      font-size: 10px;
      color: #0007139f;
      text-align: left;
      svg {
        font-size: 14px;
        position: relative;
        top: 3px;
        margin-right: 5px;
      }
    }
  }
}
