.message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0;

  &.message-self {
    align-items: center;
    justify-content: flex-end;

    .message-contents {
      background-color: var(--surface_page);
    }
  }

  .message-contents {
    padding: 8px;
    border-radius: 12px;
    margin-left: 8px;
    min-width: 125px;
    max-width: 75%;
    overflow: hidden;
    background-color: var(--background);
    box-shadow: 0 1px 4px var(--box_shadow);

    .message-contents-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;

      & {
        display: inline-flex;
        align-self: center;
      }

      &-name {
        font-size: 13px;
        font-weight: 600;
      }

      &-separator {
        font-size: 11px;
        font-weight: 400;
        color: var(--text4);
        margin: 0 4px;
      }

      &-time {
        font-size: 11px;
        font-weight: 500;
        color: var(--text4);
      }
    }

    .message-contents-text {
      > * {
        margin: 0;
      }

      &.deleted-message {
        font-size: 13px;
        font-weight: 400;
        color: var(--text3);
        font-style: italic;

        > * {
          display: inline;
          vertical-align: middle;
        }
      }
    }

    .message-seemore {
      font-size: 13px;
      font-weight: 400;
      color: var(--text2);
      margin: 0;
    }

    .message-contents-footer {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 4px;
      margin-top: 2px;

      .messageTime {
        font-size: 11px;
        font-weight: 400;
        color: var(--text3);
      }
    }
  }

  .message-menu-button {
    height: 24px;
    width: 24px;
    align-self: center;
    margin-left: 8px;
    // background-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    &.self {
      margin: 0;
      // margin-right: 12px;
    }
  }

  &.blink {
    animation: blink 0.5s linear infinite;

    @keyframes blink {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
